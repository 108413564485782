import React, {useEffect, useState} from 'react';
import {
  Button,
  Grid,
  Typography,
  FormControl,
  InputAdornment,
  Input,
  Box,
  CircularProgress
} from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import BatchReviewIcon from "../../../assets/dashboard/batch_review.png"
import BatchHistoryIcon from "../../../assets/dashboard/batch_history.png"
import MakePaymentIcon from "../../../assets/dashboard/make_payment.png"
import MakeRequestIcon from "../../../assets/dashboard/make_request.png"
import MerchantInfoIcon from "../../../assets/dashboard/merchant_info.png"
import SystemSettingsIcon from "../../../assets/dashboard/system_settings.png"
import MoreSoonIcon from "../../../assets/dashboard/more_soon.png"
import {useDispatch, useSelector} from "react-redux";
import {getBatchHistory} from "../../../store/actions/batchActions";
import DashboardCard, { IDashboardCardProps } from "./DasboardCard";
import TransactionRow from "./TransactionRow";
import {LOCALSTORAGE_VALUES, PROTECTED_ROUTES} from "../../../shared/constants/app-constants";
import {getLocalstorageValue} from "../../../utils/localstorage";
import useScreenSize from "../../../hooks/useScreenSize";
import {COLORS, maxWidthMobileScreen} from "../../../shared/constants/layout-constants";
import {Link} from "react-router-dom";

const cards: IDashboardCardProps[] = [
  {
    icon: MakePaymentIcon,
    text: "Make Payment",
    route: PROTECTED_ROUTES.PAYMENT_FORM
  },
  {
    icon: MakeRequestIcon,
    text: "Make Request",
    disabled: true
  },
  {
    icon: BatchReviewIcon,
    text: "Batch Review"
  },
  {
    icon: BatchHistoryIcon,
    text: "Batch History",
    route: PROTECTED_ROUTES.BACTH_HISTORY
  },
  {
    icon: MerchantInfoIcon,
    text: "Merchant Info",
    disabled: true
  },
  {
    icon: SystemSettingsIcon,
    text: "System Settings",
    disabled: true
  },
  {
    icon: MoreSoonIcon,
    text: "More Soon"
  }
]

const AdminDashboard = () => {
  const {width:screenWidth} = useScreenSize()
  const isMobile = screenWidth < maxWidthMobileScreen
  const dispatch = useDispatch();
  const [transactions, setTransactions] = useState<any[]>([])
  const { my_user } = useSelector((state: any) => state?.main)
  const [loading, setLoading] = useState<boolean>(true);

  const handleBatchHistoryRequest = () => {
    const { cLog__LPassword } = getLocalstorageValue(LOCALSTORAGE_VALUES.CREDENTIALS)

    dispatch(getBatchHistory({ cTrxH__User: my_user.email, cTrxH__Password : cLog__LPassword, cTrxH__ReqType: "1" },
      (response) => {
        setTransactions(response.transactions)
        setLoading(false)
      },
      () =>{},
      "Request successful.", true, true
    ))
  }

  useEffect(() => {
    handleBatchHistoryRequest()
  }, []);

	return (
      <>
      {
        loading ?
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems:"center",paddingTop:'10%' }} alignContent="center">
            <CircularProgress color="primary"  />
          </Box>
          :
          <Grid container flexDirection="column" alignContent={'center'} justifyContent={'center'} >
              <Grid item xs={12}>
                <Box style={{margin:'30px auto auto 10px'}}>
                  <Typography variant="h4">
                    Hello, Walter
                  </Typography>
                  <Button size="large" style={{textTransform: 'none',paddingLeft:0}} endIcon={<KeyboardArrowRightIcon/>}>
                    View Profile
                  </Button>
                </Box>
              </Grid>
    
            

            <Box style={{display:'flex',justifyContent:'space-between', width:'100%', maxWidth:'1200px',marginBottom:50}} flexWrap={"wrap"}>
              {
                cards.map((card) => (
                      <DashboardCard {...card} />
                    ))
              }
            </Box>
            
            
            <Grid container item justifyContent="space-between" alignItems="center" style={{ maxWidth:'1200px',marginBottom:30}}>
              <Grid container item xs={8} md={4}>
                <Typography fontWeight="bolder" variant="h6">Current Transactions</Typography>
              </Grid>
              <Grid container item xs={4} md={8} justifyContent="flex-end" alignItems={"center"}>
                {
                    !isMobile && (<>
                      <FormControl variant="standard">
                        <Input
                            size="small"
                            placeholder="Search"
                            style={{border:'.6px solid #C4C4C4'}}
                            name="search-input"
                            disableUnderline={true}
                            sx={{borderRadius:15, paddingLeft:'10px',paddingTop:1, width:'187px', color:'#60737D'}}
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon sx={{width:18,color:'#60737D',marginTop:-.5}} />
                              </InputAdornment>
                            }
                        />
                      </FormControl>
                      <Button size="large" style={{textTransform: 'none',fontWeight:'bold'}} startIcon={<TuneIcon/>}>
                        Filters
                      </Button>
                    </>)
                }
               
                <Link to="/batch-history" style={{fontSize:15,fontFamily:'NotoSans-Regular',color:COLORS.BRAND_PRIMARY,textDecoration:'none', cursor:'pointer',marginTop:2,fontWeight:'bold'}}>View All</Link>
              
              </Grid>
            </Grid>
            {
              transactions.length ? transactions.map((trx) => (
                <TransactionRow {...trx} isMobile={isMobile} />
              )) :
                <Grid container item justifyContent="center" alignItems="center" style={{marginTop: "3em", maxWidth:'1200px'}}>
                  <Typography variant="h5" color="#a1a1a1">Sorry, we couldn't find recent transactions...</Typography>
                </Grid>
            }
          </Grid>
      }
      </>
  )
};

export default AdminDashboard;
