import React from "react";
import {typeMask} from "./constant";
import {CustomProps} from "./interfaces";
import {IMaskInput} from "react-imask";
import CurrencyInput from "react-currency-input-field";

export const TextMaskCustom = (
    formik:any,
    type:typeMask,
    value:any,
    setValue:Function,
    focus:any,
    setFocus:Function) => React.forwardRef<HTMLInputElement, CustomProps>(
    
    function TextMaskCustom(props, ref) {
        const {onChange,  name, ...other } = props;
        
        const handlerCurrencyOnBlur = (event:any) => {
            const amountNumber = event.target.value.slice(2, event.target.value?.length).replace(/,/g,"")
            const hasDecimal = event.target.value?.length && event.target.value.includes(".")
            
            formik.setTouched({amount:true},true )
            setFocus(false)
            
            if(hasDecimal ) {
                const decimals = amountNumber.split(".")[1]
                setValue(decimals.length===1 ? amountNumber.concat('0'):amountNumber )
            } else {
                if (amountNumber.length > 1) {
                    setValue(amountNumber.concat('.00'))
                }
            }
        }
        
        return (
            type === typeMask.CREDIT_CARD ? <IMaskInput
                    {...other}
                    name={"cardNumber"}
                    id={"cardNumber"}
                    autoFocus={focus}
                    mask="0000 0000 0000 0000"
                    definitions={{ '#': /[1-9]/ }}
                    value={value}
                    inputRef={ref}
                    overwrite
                    onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                    onFocus={() => setFocus(true)}
                    onBlur={()=> {
                        formik.setTouched({cardNumber:true},true )
                        setFocus(false)
                    }}
                />:
                <CurrencyInput
                    ref={ref}
                    autoFocus={focus}
                    allowDecimals={true}
                    fixedDecimalLength={2}
                    prefix="$ "
                    decimalsLimit={2}
                    decimalSeparator={"."}
                    disableGroupSeparators = {false}
                    value={value}
                    className={'currency-input'}
                    onValueChange={(value: any) => {
                        console.log("?? ",value)
                        onChange({ target: { name: props.name, value } })
                    }}
                    onFocus={() => setFocus(true)}
                    onBlur={handlerCurrencyOnBlur}
                    formatValueOnBlur={true}
                    style={{width:'100%'}}
                />
        );
    },
);
    
