import {Divider, Stack, TextField, Typography} from "@mui/material";
import {getYears, initialValuesStep2, months, typeMask, validationSchemaStep2} from "./constant";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {useNavigate} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import {useDispatch, useSelector} from "react-redux";

import './payment-page.css'
import {IInitialValues} from "./interfaces";
import {getLocalstorageValue} from "../../../utils/localstorage";
import {LOCALSTORAGE_VALUES} from "../../../shared/constants/app-constants";
import {formatAmount, getExpiredDateFormat, removeDashesFromCreditCard} from "../../../utils/helpers";
import {makePayment} from "../../../store/actions/paymentActions";
import DropdownList from "../../../components/general/DropdownList";
import useScreenSize from "../../../hooks/useScreenSize";
import {TextMaskCustom} from "./TextMaskCustom";
import ResponseTransactionComponent from "./ResponseTransactionComponent";
import CancelPaymentProcessDialog from "./CancelPaymentProcessDialog";
import {maxWidthMobileScreen} from "../../../shared/constants/layout-constants";
import Box from "@mui/material/Box";
import DialogPopUp from "../../../components/general/DialogPopUp/DialogPopUp";

interface IPaymentResponse{
    status:boolean,
    transactionNumber: string,
    amount: number
}
const PaymentFormStep2 = () => {
    
    const dispatch = useDispatch()
    const { my_user, paymentStep1 } = useSelector((state: any) => state?.main)

    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
    const [errorSubmit, setErrorSubmit] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const [response, setResponse] = useState<IPaymentResponse | undefined>()
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
    const [numberCard, setNumberCard] = useState<string>('');
    const [cardInputFocus, setCardInputFocus] = useState<boolean>(false)
    
    const {width} = useScreenSize();
    
    const selectorRWidth = width > 1024 ? 50: 100;
    const selectorLWidth = width > 1024 ? selectorRWidth - 1: selectorRWidth;
    const cardStyle = width>maxWidthMobileScreen ? {minWidth:'650px',backgroundColor:'#FFF',borderRadius:'12px',padding:'20px'}:{backgroundColor:'#FFF',borderRadius:'12px',padding:'20px'}

    // @ts-ignore
    const handleSubmit = (values:IInitialValues, onSubmitProps) => {
        console.log("Step1 values: ", paymentStep1.amount, paymentStep1.invoiceNumber)
        setLoadingRequest(true)
        const { cLog__LPassword } = getLocalstorageValue(LOCALSTORAGE_VALUES.CREDENTIALS)
        const cardNumberOnly = removeDashesFromCreditCard(values.cardNumber)
        
        dispatch(
            makePayment(
                {
                    cTrxA__User : my_user.email,
                    cTrxA__Password : cLog__LPassword,
                    cTrxA__Inv_Num : paymentStep1.invoiceNumber.toUpperCase(),
                    cTrxA__CardEMail : values.cardEmail,
                    cTrxA__Amount : paymentStep1.amount,
                    cTrxA__CardName : values.cardName.toUpperCase(),
                    cTrxA__CardNumber : cardNumberOnly,
                    cTrxA__CardData : getExpiredDateFormat(values.cardMonth,values.cardYear),
                    cTrxA__Street : values.street.toUpperCase(),
                    cTrxA__Zip : values.zip,
                    cTrxA__CVV2 : values.cvv
                },
                (args) => {
                    const successfullPayment = args?.status === true
                    setLoadingRequest(false)
                    setIsSubmitted(successfullPayment)
                    if(successfullPayment){
                        setResponse({
                            status: args?.status,
                            amount: parseFloat(paymentStep1.amount),
                            transactionNumber:args?.message
                        })
                    }else{
                        setShowError(true)
                        setResponse({
                            status: args?.status,
                            amount: parseFloat(paymentStep1.amount),
                            transactionNumber:args?.message
                        })
                    }
                    
                    if(args?.status) {
                        onSubmitProps.resetForm()
                    }
                },
                (args) => {
                    console.error("ERROR ARGS: ", args)
                },
                "payment successful.", true, true
            )
        );
    };
    
    const formik = useFormik({
        initialValues: initialValuesStep2,
        validationSchema: validationSchemaStep2,
        onSubmit: handleSubmit
    });
    
    const handleCardChange = async(event: any) => {
        setNumberCard(event.target.value)
    }
    
    const handleRequestError = (val:boolean) => {
        setErrorSubmit(val)
        setShowError(val)
    }
    
    useEffect(() => {
        formik.setFieldValue('cardNumber', numberCard)
    }, [numberCard]);
    
    useEffect(() => {
        if(showError){
            setErrorSubmit(true)
        }
   
    },[showError])
    
    if(isSubmitted){
        return <ResponseTransactionComponent status={response?.status ?? false} transactionNumber={response?.transactionNumber ?? ''} amount={response?.amount ?? 0}/>
    }

    
    return(
        <Box sx={cardStyle}>
               <Typography variant="h3" fontWeight="bolder" style={{color:"#333333",fontSize:'20px',fontFamily:'NotoSans-Regular',marginBottom:'20px'}}>Payment Details</Typography>
                <Typography variant="h4" fontWeight="bolder" style={{color:"#333333",fontSize:'20px',fontFamily:'NotoSans-Regular',marginBottom:'16px'}} >Payment</Typography>
                <Divider style={{color:"#E3E8EF",marginBottom:'12px'}}/>
                <form onSubmit={formik.handleSubmit}>
                    <Typography variant='body1' fontSize={'16px'} fontWeight={600} style={{color:"#333333",backgroundColor:'transparent',marginBottom:'-12px'}}>Card Number</Typography>
                    <TextField key={`card-number-inputField`}
                               inputProps={{'data-testid': `card-number-inputField`}}
                               style={{marginTop:14,marginBottom:9}}
                               fullWidth
                               placeholder="1234 5678 9101 1121"
                               id={"cardNumber"}
                               name={"cardNumber"}
                               onChange={handleCardChange}
                               value={formik.values["cardNumber"]}
                               error={formik.touched["cardNumber"] && Boolean(formik.errors["cardNumber"])}
                               helperText={formik.touched["cardNumber"] && formik.errors["cardNumber"]}
                               InputProps={{
                                   inputComponent: TextMaskCustom(formik, typeMask.CREDIT_CARD, numberCard, setNumberCard, cardInputFocus, setCardInputFocus ) as any,
                               }}
                    />
                    
                    <Typography variant='body1' fontSize={'16px'} fontWeight={600} style={{color:"#333333",backgroundColor:'transparent',marginBottom:'-12px'}}>Card Holder Name</Typography>
                    <TextField margin="normal" key={`card-name-inputField`}
                               inputProps={{
                                   'data-testid': `card-name-inputField`,
                                   style: {textTransform: "uppercase"}
                               }}
                               fullWidth
                               placeholder="Ex. Jane Cooper"
                               id="cardName"
                               name="cardName"
                               value={formik.values["cardName"]}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               error={formik.touched["cardName"] && Boolean(formik.errors["cardName"])}
                               helperText={formik.touched["cardName"] && formik.errors["cardName"]}
                    />
                    
                    <Stack spacing={{ xs: '0%',sm:'1%' }} direction="row" useFlexGap flexWrap="wrap" alignContent={'space-between'} marginTop={.5} >
                        <Stack style={ {width:`${selectorLWidth}%`}}>
                            <Typography variant='body1' fontSize={'16px'} fontWeight={600} style={{color:"#333333",backgroundColor:'transparent',marginBottom:'-12px'}}>Expiration Month</Typography>
                            <DropdownList name={'cardMonth'} label={"Expiration Month"} data={months} formik={formik} defaultNumber='Select Month'/>
                        </Stack>
                        <Stack style={ {width:`${selectorLWidth}%`}}>
                            <Typography variant='body1' fontSize={'16px'} fontWeight={600} style={{color:"#333333",backgroundColor:'transparent',marginBottom:'-12px'}}>Expiration Year</Typography>
                            <DropdownList name={'cardYear'} label={"Expiration Year<"}  data={getYears()} formik={formik} defaultNumber='select year'/>
                        </Stack>
                    </Stack>
                    
                    <Typography variant='body1' fontSize={'16px'} fontWeight={600} style={{color:"#333333",backgroundColor:'transparent',marginBottom:'-12px'}}>Security Code (CVV)</Typography>
                    <TextField key={'cvv-inputField'}
                               inputProps={{
                                   'data-testid': `cvv-inputField`,
                                   style: {textTransform: "uppercase"}
                               }}
                               style={{marginTop:14,marginBottom:9}}
                               fullWidth
                               placeholder="Ex. 982"
                               id={'cvv'}
                               name={'cvv'}
                               onChange={formik.handleChange}
                               value={formik.values["cvv"]}
                               error={formik.touched["cvv"] && Boolean(formik.errors["cvv"])}
                               helperText={formik.touched["cvv"] && formik.errors["cvv"]}
                    />
                    
                    <Typography variant='body1' fontSize={'16px'} fontWeight={600} style={{color:"#333333",backgroundColor:'transparent',marginBottom:'-12px'}}>Card Billing Address</Typography>
                    <TextField key={`card-address-inputField`}
                               inputProps={{
                                   'data-testid': `card-address-inputField`,
                                   style: {textTransform: "uppercase"}
                               }}
                               style={{marginTop:14,marginBottom:9}}
                               fullWidth
                               placeholder="Ex. 8320 Main"
                               id="street"
                               name="street"
                               onChange={formik.handleChange}
                               value={formik.values["street"]}
                               error={formik.touched["street"] && Boolean(formik.errors["street"])}
                               helperText={formik.touched["street"] && formik.errors["street"]}
                    />
                    
                    <Typography variant='body1' fontSize={'16px'} fontWeight={600} style={{color:"#333333",backgroundColor:'transparent',marginBottom:'-12px'}}>Zip code</Typography>
                    <TextField key={'zip-inputField'}
                               style={{marginTop:14,marginBottom:9}}
                               fullWidth
                               inputProps={{'data-testid': 'zip-inputField', style: { textTransform: "uppercase" }}}
                               id={'zip'}
                               name={'zip'}
                               placeholder="Ex. 73923"
                               onChange={formik.handleChange}
                               value={formik.values["zip"]}
                               error={formik.touched["zip"] && Boolean(formik.errors["zip"])}
                               helperText={formik.touched["zip"] && formik.errors["zip"]}
                    />
                    
                    <Typography variant='body1' fontSize={'16px'} fontWeight={600} style={{color:"#333333",backgroundColor:'transparent',marginBottom:'-12px'}}>Card E-mail</Typography>
                    <TextField margin="normal" key={`card-email-inputField`}
                               inputProps={{
                                   'data-testid': `card-email-inputField`,
                                   style: {textTransform: "lowercase"}
                               }}
                               fullWidth
                               placeholder="Ex. jane@mail.com"
                               id="cardEmail"
                               name="cardEmail"
                               value={formik.values["cardEmail"]}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               error={formik.touched["cardEmail"] && Boolean(formik.errors["cardEmail"])}
                               helperText={formik.touched["cardEmail"] && formik.errors["cardEmail"]}
                    />
                    
                    <LoadingButton
                        color={'primary'}
                        data-testid="submit-payment-btn"
                        type="submit"
                        variant="contained"
                        fullWidth={true}
                        style={{textTransform:'capitalize'}}
                        loading={loadingRequest}
                        disabled={!(formik.dirty && formik.isValid)}>
                        {
                            `Pay $${formatAmount(parseFloat(paymentStep1.amount))}`
                        }
                    </LoadingButton>
                </form>
                <CancelPaymentProcessDialog/>
                <DialogPopUp
                    display={errorSubmit}
                    screenType={width<maxWidthMobileScreen ? 'Mobile' : 'Desktop'}
                    message={response?.transactionNumber ?? ''}
                    action={handleRequestError}
                />
            </Box>
    )
}

export default PaymentFormStep2